import { NextPage, NextPageContext } from 'next';
import { useRouter } from 'next/router';
import { ROUTE } from 'src/apis/const';
import { DefaultHeadDom } from 'src/components';
import Button, { ButtonSize, ButtonStyle } from 'src/components/common/button';

const Error: NextPage<{ statusCode?: number }> = () => {
  const router = useRouter();
  return (
    <>
      <DefaultHeadDom />
      <section>
        <div>
          <h1>{'Something went wrong.'}</h1>
          <p>Sorry, the content you are looking for could not be found.</p>
        </div>
        <Button
          label="Go to Home"
          buttonStyle={ButtonStyle.PRIMARY}
          buttonSize={ButtonSize.L}
          handleClick={() => {
            router.push(ROUTE.HOME);
          }}
        />
      </section>

      <style jsx>{`
        section {
          width: 100%;
          height: calc(100vh - 240px);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
        div {
          width: 500px;
          text-align: center;
          color: var(--WT100);
          font-size: 1.4rem;
          margin-bottom: 30px;
        }
        h1 {
          font-size: 4rem;
          margin-bottom: 20px;
        }
        p {
          color: var(--WT100);
        }
      `}</style>
    </>
  );
};

Error.getInitialProps = ({ res, err }: NextPageContext) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  return { statusCode };
};

export default Error;
